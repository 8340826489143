/*! main.js | Bulkit | CSS Ninja */

/* ==========================================================================
Core JS file
========================================================================== */

'use strict'

//1. Preload page
initPageLoader()

$(document).ready(function ($) {
  //2. Lazy loading
  const el = document.querySelectorAll('[data-lazy-load]')
  const observer = lozad(el, {
    loaded: function (el) {
      // Custom implementation on a loaded element
      el.parentNode.classList.add('loaded')
    },
  })

  observer.observe()

  //3. Change to demo content (if env)
  if (env === 'development') {
    changeDemoImages()
  }

  //4. Init Feather icons
  feather.replace()

  //5. Init Layout
  initNavbar()
  initLandingNavbar()
  initBackgroundImages()

  //6. Components
  initNavigationTabs()
  initVerticalTabs()

  //10. Demo
  initScrollspyNav()
  initParallax()
  initBackToTop()

  //11. Utility functions
  initGitem()
  initAnchorScroll()
  initQuickview()
  initScrollReveal()
  initMarquee()

  //12. Page specific methods
  initMockup()
  //initBlog();
})
